import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rankPipe'
})
export class RankPipe implements PipeTransform {
  transform(value: number, args?: any): string {
    if (value) {
      switch (value) {
        case 1:
          return '1er';

        default:
          return value + 'ème';
      }
    } else {
      return '';
    }
  }
}
