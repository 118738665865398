import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Discipline } from './discipline.model';
import { map } from 'rxjs/operators';
import { Event } from '../event/event.model';

@Injectable()
export class DisciplineService {
  private url = '/api/live/disciplines';

  constructor(private http: HttpClient) {}

  public getDisciplines(event: Event): Observable<Discipline[]> {
    if (event) {
      return this.http.get(`${this.url}?eventId=${event.id}`).pipe(map(data => <Discipline[]>data));
    } else {
      return this.http.get(`${this.url}`).pipe(map(data => <Discipline[]>data));
    }
  }
}
