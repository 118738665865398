import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'live-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  public altPartner = '';

  public hrefPartner: string;

  public srcPartner: string;

  constructor(private httpClient: HttpClient) {}

  ngOnInit(): void {
    const headers = new HttpHeaders({
      'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
      Pragma: 'no-cache',
      Expires: '0',
    });
    this.httpClient.get('config/partner.json', { headers: headers }).subscribe((data: any) => {
      this.altPartner = data.alt;
      this.hrefPartner = data.href;
      this.srcPartner = data.src;
    });
  }
}
