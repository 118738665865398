import { ResultService } from './../../shared/result/result.service';
import { DecimalPipe } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { Team } from '../../shared/team/team.model';
import { Group } from '../../shared/group/group.model';
import { Category } from '../../shared/category/category.model';
import { Entity } from '../../shared/entity/entity.model';
import { EntityType } from '../../shared/entity-type.enum';
import { Apparatus } from '../../shared/apparatus/apparatus.model';
import { ApparatusMark } from '../../shared/mark/apparatus-mark.model';
import { CorpsMark } from '../../shared/mark/corps-mark.model';
import { PassageMark } from '../../shared/mark/passage-mark.model';
import { Mark } from '../../shared/mark/mark.model';
import { tryParse } from 'selenium-webdriver/http';
import { AngularWaitBarrier } from 'blocking-proxy/built/lib/angular_wait_barrier';
import { isNumber } from 'util';

@Component({
  selector: 'live-details-dialog',
  templateUrl: './details-dialog.component.html',
  styleUrls: [
    './details-dialog.component.scss',
    '../result.component.scss',
    '../result.component.small.scss'
  ]
})
export class DetailsDialogComponent implements OnInit {
  public team: Team;
  public group: Group;
  public entities: Entity[];
  public category: Category;
  public currentEntity: Entity;
  public displayedColumns = ['note'];
  public dataSource = [];
  public photo: string;
  public entityTypeEnum = EntityType;

  private static mod(a: number, n: number): number {
    const res = a % n;
    return res < 0 ? res + n : res;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private decimalPipe: DecimalPipe,
    private resultService: ResultService
  ) {}

  public ngOnInit() {
    this.team = this.data.row;
    this.group = this.data.row;
    this.category = this.data.category;
    // Athlete can be the whole object or the index on the list of athletes in either a team or a group

    if (this.category.entityType === this.entityTypeEnum.Team || this.category.entityType === this.entityTypeEnum.MF) {
      this.entities = this.team.entities;
      if (isNumber(this.data.entity)) {
        this.currentEntity = this.entities[this.data.entity];
      } else {
        this.currentEntity = this.data.entity;
      }
    } else if (this.category.entityType === this.entityTypeEnum.Group) {
      this.entities = this.group.entities;
      this.entities.forEach(e => e.mark = this.group.mark);
      this.currentEntity = this.entities[0];
    } else {
      this.currentEntity = this.data.entity;
    }
    this.dataSource.push(this.currentEntity.mark);
    if (!this.currentEntity.cnil) {
      this.resultService.getGymPhoto(this.currentEntity.licence).subscribe(res => {
        if (res && res.url && res.url.length > 0) {
          this.photo = res.url;
        }
      });
    }

    for (const app of this.category.apparatus) {
      for (let i = 0; i < app.nbPassage; i++) {
        this.displayedColumns.push(app.label + i);
      }
    }
    this.displayedColumns.push('total');
  }

  /**
   * get the mark of an app
   * @param mark mark
   * @param apparatus apparatus
   * @param numPassage passage
   */
  public getAppMark(mark: Mark, apparatus: Apparatus, numPassage: number): number | string {
    if (mark && mark.appMarks) {
      const apparatusMark = mark.appMarks.find(appMark => appMark.codeApp === apparatus.code);
      if (apparatusMark) {
        if (apparatusMark.passageMarks) {
          if (apparatusMark.passageMarks[numPassage]) {
            return this.decimalPipe.transform(
              apparatusMark.passageMarks[numPassage].value,
              '1.3-3'
            );
          }
        } else {
          return this.decimalPipe.transform(apparatusMark.value, '1.3-3');
        }
      }
    }
    // if we don't any marks, we return '-' instead of nothing
    return '-';
  }

  /**
   * get the corps marks of a passage of a apparatus of an athlete or group of the current category
   * @param entity athlete or group
   * @param apparatus apparatus
   * @param numPassage passage
   */
  public getAppCorpsMark(mark: Mark, apparatus: Apparatus, numPassage: number): CorpsMark[] {
    const result: CorpsMark[] = [];
    if (mark && mark.appMarks) {
      const appMarks = mark.appMarks.find(appMark => appMark.codeApp === apparatus.code);

      if (appMarks && appMarks.passageMarks[numPassage]) {
        for (const corpsMark of appMarks.passageMarks[numPassage].corpsMarks) {
          for (const corps of this.category.corpsMarksLabels) {
            if (corpsMark) {
              if (corpsMark.corps === corps) {
                result.push(corpsMark);
              }
            } else {
              // if we don't any marks, we return an empty mark in order to display '-' instead of nothing
              result.push(new CorpsMark());
            }
          }
        }
      } else {
        // if we don't any marks, we return an empty mark in order to display '-' instead of nothing
        for (const corps of this.category.corpsMarksLabels) {
          result.push(new CorpsMark());
        }
      }
    }

    return result;
  }

  public nextEntity(isNext: boolean): void {
    const entityIndex = DetailsDialogComponent.mod(
        this.entities.indexOf(this.currentEntity) + (isNext ? 1 : -1),
        this.entities.length
      );
    this.goToEntity(entityIndex);
  }

  public goToEntity(index: number): void {
    this.photo = null;
    this.currentEntity = this.entities[index];
    this.dataSource = [this.currentEntity.mark];
    if (!this.currentEntity.cnil) {
      this.resultService.getGymPhoto(this.currentEntity.licence).subscribe(res => {
        if (res && res.url && res.url.length > 0) {
          this.photo = res.url;
        }
      });
    }
  }
}
