import { Injectable } from '@angular/core';
import { SearchInfo } from './search-info.model';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable()
export class HeaderService {
  private searchInfo: SearchInfo;
  public searchInfo$: Observable<SearchInfo>;
  private searchInfoSubject: BehaviorSubject<SearchInfo> = new BehaviorSubject<SearchInfo>(
    this.searchInfo
  );

  constructor() {
    this.searchInfo$ = this.searchInfoSubject.asObservable();
    this.reset();
  }

  public reset() {
    this.searchInfo = new SearchInfo();
    this.searchInfoSubject.next(this.searchInfo);
  }
}
