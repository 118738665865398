import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'live-marks',
  templateUrl: './marks.component.html',
  styleUrls: ['./marks.component.scss']
})
export class MarksComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
