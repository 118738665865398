import { DecimalPipe } from '@angular/common';
import { DisciplineService } from './shared/discipline/discipline.service';
import { MarksRoutingModule } from './marks-routing.module';
import { ResultComponent } from './result/result.component';
import { DisciplinesComponent } from './home/disciplines/disciplines.component';
import { NgModule } from '@angular/core';
import { MarksComponent } from './marks.component';
import { SharedModule } from '../shared/shared.module';
import { EventsComponent } from './home/events/events.component';
import { HomeComponent } from './home/home.component';
import { HomeService } from './shared/home.service';
import { CookieService } from 'ngx-cookie-service';
import { RegionsComponent } from './home/regions/regions.component';
import { CategoriesComponent } from './home/categories/categories.component';
import { RegionService } from './shared/region/region.service';
import { ResultService } from './shared/result/result.service';
import { DetailsDialogComponent } from './result/details-dialog/details-dialog.component';
import { HeaderService } from './shared/header.service';

@NgModule({
  declarations: [
    HomeComponent,
    DisciplinesComponent,
    EventsComponent,
    ResultComponent,
    MarksComponent,
    MarksComponent,
    RegionsComponent,
    CategoriesComponent,
    DetailsDialogComponent
  ],
  imports: [MarksRoutingModule, SharedModule],
  providers: [
    RegionService,
    HomeService,
    HeaderService,
    CookieService,
    DisciplineService,
    ResultService,
    DecimalPipe
  ],
  entryComponents: [DetailsDialogComponent]
})
export class MarksModule {}
