import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

/**
 * @description Service surchargeant la classe Http pour la connexion au webservice du Back-Office
 */
@Injectable()
export class HttpService {
  private auth = 'Basic ' + btoa(environment.server.username + ':' + environment.server.password);
  private headers = new Headers({
    'Content-Type': 'application/json',
    Authorization: this.auth
  });
  private headersFile = new Headers({});
  private requestOptions = new RequestOptions({ headers: this.headers });
  private requestOptionsFile = new RequestOptions({ headers: this.headersFile });

  constructor(private http: Http, private router: Router) {}

  /**
   * @description Surcharge de la méthode get, utilisée pour la récupération
   * @param URL Chemin URL
   * @return Observable sur la réponse HTTP
   */
  public get(URL: string): Observable<any> {
    return this.http
      .get(URL, this.requestOptions)
      .pipe(catchError(error => this.handleError(error)));
  }

  /**
   * @description Surcharge de la méthode post, utilisé pour la création
   * @param URL Chemin URL
   * @param data Élément à créer
   * @return Observable sur la réponse HTTP
   */
  public post(URL: string, data: any): Observable<any> {
    return this.http.post(URL, data).pipe(catchError(error => this.handleError(error)));
  }
  /**
   * @description Surcharge de la méthode delete, utilisé pour la suppression
   * @param URL Chemin URL
   * @return Observable sur la réponse HTTP
   */
  public delete(URL: string): Observable<any> {
    return this.http.delete(URL).pipe(catchError(error => this.handleError(error)));
  }

  /**
   * @description Surcharge de la méthode put, utilisé pour la modification
   * @param URL Chemin URL
   * @return Observable sur la réponse HTTP
   */
  public put(URL: string, data: any): Observable<any> {
    return this.http.put(URL, data).pipe(catchError(error => this.handleError(error)));
  }

  /**
   * @description Surcharge de la méthode put, utilisé pour la modification
   * @param URL Chemin URL
   * @return Observable sur la réponse HTTP
   */
  public putFile(URL: string, data: any): Observable<any> {
    // Ajout de headers
    return this.http
      .put(URL, data, this.requestOptionsFile)
      .pipe(catchError(error => this.handleError(error)));
  }

  /**
   * @description Traiter des erreurs reçues dans la réponse HTTP
   * @param error Erreur reçue
   */
  private handleError(error: any): Observable<any> {
    // On masque le loader en cas d'erreur
    switch (error.status) {
      case 401:
        break;
      case 404:
        break;
      case 500:
        break;
      default:
        break;
    }

    throw error;
  }
}
