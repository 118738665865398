import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Region } from './region.model';

@Injectable()
export class RegionService {
  private url = '/api/live/regions';
  constructor(private http: HttpClient) {}

  public getRegions(codeDiscipline: String): Observable<Region[]> {
    return this.http
      .get(`${this.url}?codeDiscipline=${codeDiscipline}`)
      .pipe(map(data => <Region[]>data));
  }
}
