import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({ name: 'highlight' })
export class HighlightPipe implements PipeTransform {
  transform(html: string, pattern: string): string {
    const text = html.replace(
      new RegExp('(' + pattern + ')', 'gi'),
      '<span class="highlighted">$1</span>'
    );
    return text;
  }
}
