import { SearchResult } from './../../core/search/search-result.model';
import { TypeResult } from './../../core/search/type-result.enum';

export class SearchInfo {
  isSearching: boolean;
  results: SearchResult[] = [];
  type = TypeResult;
  pattern = '';
  displayResult = false;
  lastResult: string;
}
