import { HttpClient } from '@angular/common/http';
import { Event } from './../event/event.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Region } from '../region/region.model';
import { Discipline } from '../discipline/discipline.model';

@Injectable()
export class EventService {
  private url = '/api/live/events';

  constructor(private http: HttpClient) {}

  getInterEvents(): Observable<Event[]> {
    return this.http.get(`${this.url}?codeLevel=F`).pipe(map(data => <Event[]>data));
  }

  getNatEvents(region: Region, discipline: Discipline): Observable<Event[]> {
    return this.http
      .get(`${this.url}?idRegion=${region.id}&codeDiscipline=${discipline.code}`)
      .pipe(map(data => <Event[]>data));
  }

  getEvent(eventId: number): Observable<Event> {
    return this.http.get(`${this.url}/${eventId}`).pipe(map(data => <Event>data));
  }
}
