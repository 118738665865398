import { HttpClient } from '@angular/common/http';
import { SearchResult } from './search-result.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class SearchService {
  private url = '/api/live/search';
  constructor(private http: HttpClient) {}

  public search(pattern: String): Observable<SearchResult[]> {
    return this.http.get(`${this.url}?pattern=${pattern}`).pipe(map(data => <SearchResult[]>data));
  }
}
