import { HeaderService } from './../shared/header.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RegionService } from './../shared/region/region.service';
import { Component, OnInit } from '@angular/core';
import { EventService } from '../shared/event/event.service';
import { Event } from '../shared/event/event.model';
import { finalize } from 'rxjs/operators';
import { Criterion } from '../shared/criterion.enum';
import { HomeService } from '../shared/home.service';
import { UserService } from 'src/live/core/user/user.service';
import { Region } from '../shared/region/region.model';
import { Category } from '../shared/category/category.model';
import { Discipline } from '../shared/discipline/discipline.model';
import { DisciplineService } from '../shared/discipline/discipline.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Context } from '../shared/context.model';

@Component({
  selector: 'live-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  // /!\ important informations about this component /!\
  // the aim of this component for the user is to select filtered categories in order to display their result
  // there are 2 possibles paths of filtering :
  // 1 - international event -> discipline -> categories
  // 2 - discipline -> regions -> local event -> categories

  // handle the visibility of the welcome message
  public isShownMessage: boolean;
  // handle the latency of the first call (inter events) to know if we can lead the user to the page of disciplines
  public areResultsReady: Boolean;
  // current criterion the user is choosing
  public currentCriterion: Criterion;
  // enum for the html
  public criterionEnum = Criterion;
  // welcome message
  public welcomeMessage: string;
  // handle the visibility of the 'previous' link
  public isPreviousLinkDisplayed: boolean;
  // context of the selection. Allows the user to keep the context of his selection
  // when he navigates between the result tab and the selections steps
  public context: Context;

  constructor(
    public userService: UserService,
    private eventService: EventService,
    private homeService: HomeService,
    private httpClient: HttpClient,
    private disciplineService: DisciplineService,
    private regionService: RegionService,
    private router: Router,
    private headerService: HeaderService,
    private route: ActivatedRoute
  ) {}

  public ngOnInit() {
    this.context = new Context();
    this.headerService.reset();
    // if we came from the result with a existing context, we need to recover it
    if (this.homeService.needContext) {
      // recover the context of the selection
      this.context = this.homeService.getContext();
      // in this case, we want to display the last criterion of the selection : Categories
      this.currentCriterion = Criterion.Category;
      // then the link must be visible
      this.isPreviousLinkDisplayed = true;
      // results are ready beacause they have been already loaded
      this.areResultsReady = true;
      // we have to set the value to this bool to false
      this.homeService.needContext = false;
    } else {
      // if we have no context, we lead the user to the 1st step of the selection

      // some controls depends on the type of the user's device
      this.isShownMessage = !this.userService.isDeviceMobile;
      // get the welcome message from the param file
      const headers = new HttpHeaders({
        'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
        Pragma: 'no-cache',
        Expires: '0',
      });
      this.httpClient
        .get('config/welcome-message.json', { headers: headers })
        .subscribe((data: any) => {
          this.welcomeMessage = data.message;
        });

        // get the international events
        this.getInterEvents();
    }

    // we subscribe the subject in order to listen to event that call the home page, so we can call getInterEvents() to do so
    this.homeService.refreshSubject.subscribe(() => {
      this.context = new Context();
      this.getInterEvents();
    });
  }

  /**
   * display or hide the information message
   */
  public toggleMessage() {
    this.isShownMessage = !this.isShownMessage;
  }

  /**
   * Do something when the user has selected an event
   * @param event event selected
   */
  public onEventSelect(event: Event): void {
    if (event) {
      this.context.eventSelected = event;
    }

    // if we have a region selected, it means that we are on the second path (see comments at the top)
    if (this.context.regionSelected) {
      this.nextCriterion(Criterion.Category);
    } else {
      this.nextCriterion(Criterion.Discipline);
    }
  }

  /**
   * do something when the user has selected a discipline
   * @param discipline discipline selected
   */
  public onDisciplineSelect(discipline: Discipline): void {
    this.context.disciplineSelected = discipline;

    // if we have a event selected, it means that we are on the first path (see comments at the top)
    if (this.context.eventSelected) {
      this.nextCriterion(Criterion.Category);
    } else {
      this.nextCriterion(Criterion.Region);
    }
  }

  /**
   * do something when the user has selected a region
   * @param region region selected
   */
  public onRegionSelect(region: Region): void {
    this.context.regionSelected = region;
    this.nextCriterion(Criterion.Event);
  }

  /**
   * do something when the user has validated the categories he has just selected
   * @param categories categories selected
   */
  public onCategoriesSelect(categories: Category[]): void {
    // get the categories selected by the user
    this.context.categoriesSelected = categories;
    // concat the ids in order to use them in the url
    const ids = this.concatIds(categories.map((cat: Category) => cat.code));
    // save the context of this selection in order for the user to retrieve it if he chooses to come back here from the result page
    this.homeService.saveContext(this.context);
    this.router.navigate(['/result/' + this.context.eventSelected.id + '/' + ids]);
  }

  /**
   * handle the click on 'previous' to display the right page
   */
  public goBack(): void {
    // the last criterions selected are saved in an array. We use it as a FIFO pile thanks to the pop method
    const crit = this.context.lastCriterions.pop();
    if (this.context.lastCriterions.length === 0) {
      // if the pile is empty, we don't need to display the previous button, obviously
      this.isPreviousLinkDisplayed = false;
    }

    // depends on the last criterion, we erase the right selection
    switch (crit) {
      case Criterion.Discipline:
        this.context.navTitle = null;
        this.context.disciplineSelected = null;
        if (!this.context.interEvents) {
          this.isPreviousLinkDisplayed = false;
        }
        break;

      case Criterion.Event:
        this.context.eventSelected = null;
        if (this.context.regionSelected) {
          this.context.navTitle =
            this.context.disciplineSelected.label + ' > ' + this.context.regionSelected.label;
          this.context.events = this.context.natEvents;
        } else {
          this.context.events = this.context.interEvents;
          this.context.navTitle = null;
        }
        break;

      case Criterion.Region:
        this.context.navTitle = this.context.disciplineSelected.label;
        this.context.regionSelected = null;
        break;

      default:
        break;
    }

    // then we go to previous criterion
    this.currentCriterion = crit;

    if (crit === Criterion.Event) {
      this.router.navigate(['/home']);
    }
  }

  // handle the click that allow the user to select others event
  public otherEvents(): void {
    this.nextCriterion(Criterion.Discipline);
  }

  /**
   * Get the internationals events
   */
  private getInterEvents(): void {
    this.eventService
      .getInterEvents()
      .pipe(
        finalize(() => {
          // we use this boolean to wait for result in order to render something stable
          this.areResultsReady = true;

          // get the event selected
          const eventId = this.route.snapshot.paramMap.get('eventId');
          if (eventId) {
            this.eventService.getEvent(parseInt(eventId, 10)).subscribe((event: Event) => {
              this.onEventSelect(event);
            });
          } else {
            if (this.currentCriterion !== Criterion.Event) {
              // if no event has been found or if the http request has failed, we go to the displine criterion
              this.nextCriterion(Criterion.Discipline, false);
            }
          }
        })
      )
      .subscribe((eventsReturned: Event[]) => {
        if (eventsReturned && eventsReturned.length > 0) {
          // if we have found some international events, we go to the event criterion with those events
          this.currentCriterion = Criterion.Event;
          this.context.interEvents = eventsReturned;
          this.context.events = this.context.interEvents;
        }
      });
  }

  // lead the user to the next criterion depending on the current one
  private nextCriterion(crit: Criterion, prev: boolean = true): void {
    // we then  need to display the previous link
    this.isPreviousLinkDisplayed = prev;
    if (!this.context.lastCriterions) {
      // init the pile if undefined
      this.context.lastCriterions = [];
    }
    this.context.lastCriterions.push(this.currentCriterion);

    switch (crit) {
      case Criterion.Event:
        this.eventService
          .getNatEvents(this.context.regionSelected, this.context.disciplineSelected)
          .subscribe((events: Event[]) => {
            this.context.natEvents = events;
            this.context.events = this.context.natEvents;

            this.context.navTitle =
              this.context.disciplineSelected.label + ' > ' + this.context.regionSelected.label;
            this.currentCriterion = crit;
          });
        break;

      case Criterion.Category:
        this.context.categories = this.context.eventSelected.categories;

        this.context.navTitle = this.context.eventSelected.label;
        this.currentCriterion = crit;
        break;

      case Criterion.Discipline:
        this.disciplineService
          .getDisciplines(this.context.eventSelected)
          .pipe(
            finalize(() => {
              this.currentCriterion = crit;
            })
          )
          .subscribe((disciplines: Discipline[]) => {
            this.context.disciplines = disciplines;
          });

        if (this.context.eventSelected) {
          this.context.navTitle = this.context.eventSelected.label;
        }
        break;

      case Criterion.Region:
        this.regionService.getRegions(this.context.disciplineSelected.code).subscribe((regions) => {
          this.context.regions = regions;

          this.context.navTitle = this.context.disciplineSelected.label;
          this.currentCriterion = crit;
        });
        break;
    }
  }

  /**
   * concat string in order to be passed as parameter in the url request
   * @param ids ids to concat
   */
  private concatIds(ids: string[]): string {
    if (ids && ids.length > 0) {
      let id = ids[0];

      id =
        id +
        ids
          .slice(1, ids.length)
          .reduce(
            (previousString: string, currentString: string) => previousString + ',' + currentString,
            ''
          );

      return id;
    } else {
      return '';
    }
  }
}
