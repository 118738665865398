import { CookieService } from 'ngx-cookie-service';
import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable()
export class UserService {
  public isDeviceMobile: boolean;

  constructor(private deviceDetectorService: DeviceDetectorService) {}

  public getDevice(): void {
    this.isDeviceMobile = this.deviceDetectorService.isMobile();
  }
}
