import { EntityType } from '../entity-type.enum';
import { PassageMark } from './passage-mark.model';

export class ApparatusMark {
  id: number;
  labelApp: string;
  codeApp: string;
  value: number;
  dossart: number;
  position: number;
  rank: number;
  entityType: EntityType;
  passageMarks: PassageMark[];
}
