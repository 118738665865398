import { ConfigService } from './core/service/config.service';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Injectable()
export class LiveInterceptor implements HttpInterceptor {
  constructor(private configService: ConfigService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const env = this.configService.environment;
    let newReq: HttpRequest<any>;

    if (req.url.startsWith('/api')) {
      newReq = req.clone({
        url: env.server.protocol + '://' + env.server.hostname + ':' + env.server.port + req.url,
        setHeaders: {
          'Content-Type': 'application/json',
          Authorization: 'Basic ' + btoa(env.server.username + ':' + env.server.password)
        }
      });
      return next.handle(newReq);
    } else {
      return next.handle(req);
    }
  }
}
