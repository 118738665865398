import { Discipline } from './../../shared/discipline/discipline.model';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Category } from '../../shared/category/category.model';
import { CategoryCheckBox } from './category-checkbox.model';

@Component({
  selector: 'live-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {
  // categories loaded from the back
  @Input() public categories: Category[];
  @Input() public discipline: Discipline;
  // categories selected
  @Output() public categoriesSelected = new EventEmitter<Category[]>();

  // handle the main check box
  public mainCheckBox: boolean;
  public categoriesCheckBoxes: CategoryCheckBox[];
  public isValidateEnable: boolean;

  constructor() {}

  public ngOnInit() {
    this.categoriesCheckBoxes = [];
    if (this.discipline) {
      for (const cat of this.categories.filter(c => c.codeDiscipline === this.discipline.code)) {
        this.categoriesCheckBoxes.push(new CategoryCheckBox(cat));
      }
    } else {
      for (const cat of this.categories) {
        this.categoriesCheckBoxes.push(new CategoryCheckBox(cat));
      }
    }
  }

  /**
   * handle the click on category
   * @param category category selected
   */
  public toggleCheckbox(category: CategoryCheckBox) {
    category.isChecked = !category.isChecked;
    if (!this.categoriesCheckBoxes.every((cat: CategoryCheckBox) => cat.isChecked === true)) {
      this.mainCheckBox = false;
    } else {
      this.mainCheckBox = true;
    }

    if (this.categoriesCheckBoxes.some((cat: CategoryCheckBox) => cat.isChecked)) {
      this.isValidateEnable = true;
    } else {
      this.isValidateEnable = false;
    }
  }

  /**
   * handle the click on the main check box
   */
  public toggleMainCheckBox(): void {
    this.mainCheckBox = !this.mainCheckBox;
    if (this.mainCheckBox) {
      for (const cat of this.categoriesCheckBoxes) {
        cat.isChecked = true;
      }
      this.isValidateEnable = true;
    } else {
      for (const cat of this.categoriesCheckBoxes) {
        cat.isChecked = false;
      }
      this.isValidateEnable = false;
    }
  }

  /**
   * give the HomeComponent the categories selected
   */
  public emitCategories(): void {
    const categorySelectedIds: string[] = this.categoriesCheckBoxes
      .filter((cat: CategoryCheckBox) => cat.isChecked)
      .map((cat: CategoryCheckBox) => cat.id);
    this.categoriesSelected.emit(
      this.categories.filter((cat: Category) => categorySelectedIds.indexOf(cat.id) !== -1)
    );
  }
}
