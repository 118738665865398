import { Event } from './../../shared/event/event.model';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'live-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {
  // events loaded from the back
  @Input() public events: Event[];
  // event selected
  @Output() public eventSelected = new EventEmitter<Event>();

  constructor() {}

  public ngOnInit() {}

  /**
   * give the homeComponent the event selected
   * @param event event selected
   */
  public emitEvent(event: Event): void {
    this.eventSelected.emit(event);
  }
}
