import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Transco } from './transco.model';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class TranscoService {
  public trancos: Transco[];
  public trancos$: Observable<Transco[]>;
  private transcosSubject: BehaviorSubject<Transco[]> = new BehaviorSubject<Transco[]>(
    this.trancos
  );
  private url = '/api/live/transco';

  constructor(private http: HttpClient) {
    this.trancos$ = this.transcosSubject.asObservable();
  }

  public getTranscos(): Observable<Transco[]> {
    if (this.trancos) {
      return of(this.trancos);
    } else {
      return this.http.get(this.url).pipe(map(data => <Transco[]>data));
    }
  }

  public getTransco(source: string): string {
    let result = source;
    if (this.trancos) {
      const index = this.trancos.findIndex(t => t.source === source);
      if (index > -1) {
        result = this.trancos[index].target;
      }
    }
    return result;
  }

  public init() {
    this.getTranscos().subscribe(res => {
      this.trancos = res;
    });
  }
}
