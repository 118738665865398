import { Criterion } from './criterion.enum';
import { Category } from './category/category.model';
import { Discipline } from './discipline/discipline.model';
import { Region } from './region/region.model';
import { Event } from './event/event.model';

export class Context {
  // title of the navigation
  navTitle: string;
  // events loaded from the back
  events: Event[];
  // categories loaded from the back
  categories: Category[];
  // disciplines loaded from the back
  disciplines: Discipline[];
  // regions loaded from the back
  regions: Region[];
  // event the user has selected
  eventSelected: Event;
  // discipline the user has selected
  disciplineSelected: Discipline;
  // region the user has selected
  regionSelected: Region;
  // categories the user has selected
  categoriesSelected: Category[];
  // inter events loaded from the back
  interEvents: Event[];
  // nat events loaded from the back
  natEvents: Event[];
  // allow us to know the order of pages the user has been on.
  lastCriterions: Criterion[];
}
