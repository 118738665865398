import { UserService } from './../../../core/user/user.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Discipline } from '../../shared/discipline/discipline.model';

@Component({
  selector: 'live-disciplines',
  templateUrl: './disciplines.component.html',
  styleUrls: ['./disciplines.component.small.scss', './disciplines.component.scss']
})
export class DisciplinesComponent implements OnInit {
  // disciplines loaded from the back
  @Input() public disciplines: Discipline[];
  // discipline selected
  @Output() public disciplineSelected = new EventEmitter<Discipline>();

  constructor(public userService: UserService) {}

  public ngOnInit() {}

  /**
   * give the homeComponent the selected
   * @param discipline discipline selected
   */
  public emitDiscipline(discipline: Discipline) {
    this.disciplineSelected.emit(discipline);
  }
}
