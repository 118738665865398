import { HttpClient } from '@angular/common/http';
import { Url } from './url.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Category } from '../category/category.model';
import { map } from 'rxjs/operators';

@Injectable()
export class ResultService {
  private url = '/api/live/results';

  constructor(private http: HttpClient) {}

  public getResult(categoriesIds: string): Observable<Category[]> {
    return this.http.get(`${this.url}/${categoriesIds}`).pipe(map(data => <Category[]>data));
  }

  public getGymCategories(id: string): Observable<string[]> {
    return this.http.get(this.url + '/gym/' + id).pipe(map(data => <string[]>data));
  }

  public getClubCategories(id: string): Observable<string[]> {
    return this.http.get(this.url + '/club/' + id).pipe(map(data => <string[]>data));
  }

  public getGymPhoto(licence: string): Observable<Url> {
    return this.http.get(this.url + '/photo/' + licence).pipe(map(data => <Url>data));
  }
}
