import { TranscoService } from './../../core/transco/transco.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'transcoCorps'
})
export class TranscoCorpsPipe implements PipeTransform {
  constructor(private transcoService: TranscoService) {}

  transform(value: string): string {
    return this.transcoService.getTransco(value);
  }
}
