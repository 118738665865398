import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Context } from './context.model';

@Injectable()
export class HomeService {
  // home component context

  // context
  private context: Context;

  // subject that the home component will subscribe
  public refreshSubject = new Subject<any>();

  public needContext: boolean;

  public saveContext(context: Context): void {
    this.context = context;
  }

  public getContext(): Context {
    return this.context;
  }

  /**
   * Method called by the root component. The next() function will trigger the subscription of the subject in the home component
   */
  public refreshHomePage(): void {
    this.refreshSubject.next();
  }
}
