import { Pipe, PipeTransform } from '@angular/core';

// this pipe allow us to enumerate over all value of an enum so we can use an ngFor in a html file for instance

@Pipe({
  name: 'enumSelect'
})
export class EnumSelectPipe implements PipeTransform {
  // value is a Object that contains 2 lines per value.
  // For instance, in the entityType enum, we have :
  // {
  //    "0": "Athlete",
  //    "1": "Team",
  //    "2": "Group",
  //    "Athlete": "0",
  //    "Team": "1",
  //    "Group": "2"
  //  }
  // we will then need to filter to get only one line per value
  transform(value: any, args?: any): any {
    const newEnum = [];
    for (const enumMember in value) {
      // tslint is complaining if this if not here. See this to know why :
      // https://stackoverflow.com/questions/40770425/tslint-codelyzer-ng-lint-error-for-in-statements-must-be-filtere
      if (value.hasOwnProperty(enumMember)) {
        // the key is a string number so we parse it
        const key: number = parseInt(enumMember, 10);
        // as told before, we have two lines per value, so we filter to get only one
        if (!isNaN(key)) {
          newEnum.push({ key: key, value: value[key] });
        }
      }
    }
    return newEnum;
  }
}
