import { EntityType } from '../entity-type.enum';

export class CorpsMark {
  id: number;
  corps: string;
  value: number;
  dossart: number;
  position: number;
  entityType: EntityType;
}
