import { FooterComponent } from './footer/footer.component';
import { CdkTableModule } from '@angular/cdk/table';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CommonModule, NgSwitchCase, DecimalPipe } from '@angular/common';
import {
  MatTableModule,
  MatSortModule,
  MatExpansionModule,
  MatDialogModule,
} from '@angular/material';
import { FormsModule } from '@angular/forms';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { EnumSelectPipe } from './enum-select.pipe';
import { NoSanitizePipe } from './pipe/no-sanitize.pipe';
import { HighlightPipe } from './pipe/highlight.pipe';
import { TranscoCorpsPipe } from './pipe/transco-corps.pipe';
import { RankPipe } from './rank.pipe';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    EnumSelectPipe,
    NoSanitizePipe,
    HighlightPipe,
    TranscoCorpsPipe,
    RankPipe,
    FooterComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    DeviceDetectorModule.forRoot(),
  ],
  exports: [
    CdkTableModule,
    TranslateModule,
    CommonModule,
    MatSlideToggleModule,
    MatTableModule,
    MatSortModule,
    NgSwitchCase,
    DeviceDetectorModule,
    NoSanitizePipe,
    TranscoCorpsPipe,
    HighlightPipe,
    MatExpansionModule,
    MatDialogModule,
    FormsModule,
    RankPipe,
    DecimalPipe,
    FooterComponent,
  ],
})
export class SharedModule {}
