import { CoreModule } from './core/core.module';
import { MarksModule } from './marks/marks.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LiveRoutingModule } from './live-routing.module';
import { LiveComponent } from './live.component';
import { SharedModule } from './shared/shared.module';
import { HttpModule } from '@angular/http';
import { LiveInterceptor } from './live-interceptor';

@NgModule({
  declarations: [LiveComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    LiveRoutingModule,
    MarksModule,
    HttpModule,
    HttpClientModule,
    CoreModule,
    SharedModule
  ],
  exports: [],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: LiveInterceptor, multi: true }],
  bootstrap: [LiveComponent]
})
export class LiveModule {}
