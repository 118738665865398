import { ConfigService } from './service/config.service';
import { TranscoService } from './transco/transco.service';
import { SearchService } from './search/search.service';
import { NgModule } from '@angular/core';
import { SharedModule } from './../shared/shared.module';
import { HttpService } from './service/http.service';
import { UserService } from './user/user.service';

@NgModule({
  declarations: [],
  imports: [SharedModule],
  providers: [HttpService, UserService, SearchService, TranscoService, ConfigService]
})
export class CoreModule {}
