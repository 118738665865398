import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Region } from '../../shared/region/region.model';

@Component({
  selector: 'live-regions',
  templateUrl: './regions.component.html',
  styleUrls: ['./regions.component.scss']
})
export class RegionsComponent implements OnInit {
  // regions loaded from the back
  @Input() public regions: Region[];
  // region selected
  @Output() public regionSelected = new EventEmitter<Region>();

  constructor() {}

  public ngOnInit() {}

  /**
   * give the homeComponent the region selected
   * @param region region selected
   */
  public emitRegion(region: Region): void {
    this.regionSelected.emit(region);
  }
}
