import { Category } from './../../shared/category/category.model';
export class CategoryCheckBox {
  id: string;
  label: string;
  isChecked: boolean;

  constructor(category: Category) {
    this.id = category.id;
    this.label = category.label;
  }
}
