import { HomeComponent } from './home/home.component';
import { EventService } from './shared/event/event.service';
import { ResultComponent } from './result/result.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'result/:eventId', component: HomeComponent },
  { path: 'result/:eventId/:ids', component: ResultComponent },
  { path: 'search/result/:type/:id', component: ResultComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [EventService],
})
export class MarksRoutingModule {}
