import { HeaderService } from './marks/shared/header.service';
import { SearchInfo } from './marks/shared/search-info.model';
import { ConfigService } from './core/service/config.service';
import { SearchResult } from './core/search/search-result.model';
import { SearchService } from './core/search/search.service';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { HomeService } from './marks/shared/home.service';
import { UserService } from './core/user/user.service';
import { finalize } from 'rxjs/operators';
import { TranscoService } from './core/transco/transco.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'live-root',
  templateUrl: './live.component.html',
  styleUrls: ['./live.component.scss', './live.component.small.scss'],
})
export class LiveComponent implements OnInit {
  public title = 'live';
  public isMenuShown: boolean;
  public envLoaded: boolean;
  public searchInfo: SearchInfo;
  private googleTagId: string;

  constructor(
    private translate: TranslateService,
    private router: Router,
    private homeService: HomeService,
    private userService: UserService,
    private searchService: SearchService,
    private transcoService: TranscoService,
    private configService: ConfigService,
    private headerService: HeaderService,
    private httpClient: HttpClient
  ) {
    this.getgoogleTagId();
  }

  ngOnInit() {
    this.headerService.searchInfo$.subscribe((infos) => (this.searchInfo = infos));
    this.configService.initConfig().subscribe((env: any) => {
      this.configService.environment = env;
      this.envLoaded = true;
      this.transcoService.init();
    });
    this.translate.setDefaultLang('fr');
    this.searchInfo.isSearching = false;
    this.isMenuShown = false;

    // get the type of user's device and save the info in the service
    this.userService.getDevice();
  }

  public toggleSearchInput() {
    this.searchInfo.isSearching = !this.searchInfo.isSearching;
  }

  public toggleMenu() {
    this.isMenuShown = !this.isMenuShown;
  }

  /**
   * Method that navigate the user to the home page
   */
  public goToHomePage(): void {
    // if the user is already on the home page (he may be selecting a category for instance),
    // nothing will happen because we navigate to the current page. But we need to lead him to the very beggining of the site
    // to do so, we use a service to call the init method of home component
    this.isMenuShown = false;
    if (this.router.url === '/home') {
      this.homeService.refreshHomePage();
    } else {
      this.router.navigate(['/home']);
    }
  }

  public displayResults(result: SearchResult) {
    this.searchInfo.lastResult = result.label;
    this.searchInfo.pattern = '';
    this.searchInfo.displayResult = false;
    const url = '/search/result/' + result.type + '/' + result.id;
    this.router.navigate([url]);
  }

  public hideResults() {
    this.searchInfo.displayResult = false;
  }

  public ignoreFocus(event) {
    event.preventDefault();
  }

  public clean() {
    this.searchInfo.pattern = '';
    this.searchInfo.lastResult = null;
    this.searchInfo.displayResult = false;
    this.goToHomePage();
  }

  public search() {
    if (this.searchInfo.pattern.length >= 3) {
      this.searchInfo.results = [];
      this.searchInfo.isSearching = true;
      this.searchService
        .search(this.searchInfo.pattern)
        .pipe(finalize(() => (this.searchInfo.isSearching = false)))
        .subscribe((res) => {
          this.searchInfo.results = res;
          this.searchInfo.displayResult = true;
        });
    } else {
      this.searchInfo.displayResult = false;
    }
  }

  private getgoogleTagId() {
    const headers = new HttpHeaders({
      'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
      Pragma: 'no-cache',
      Expires: '0',
    });
    // tslint:disable-next-line:no-any
    this.httpClient
      .get('config/config-google-analytics.json', { headers })
      .subscribe((data: any) => {
        this.googleTagId = data.googleTagId;
        if (this.googleTagId) {
          // register google tag manager
          const gTagManagerScript = document.createElement('script');
          gTagManagerScript.async = true;
          gTagManagerScript.src = `https://www.googletagmanager.com/gtag/js?id=${this.googleTagId}`;
          document.head.appendChild(gTagManagerScript);
          // register google analytics
          const gaScript = document.createElement('script');
          gaScript.innerHTML = `
          window.dataLayer = window.dataLayer || [];
          function gtag() { dataLayer.push(arguments); }
          gtag('js', new Date());
          gtag('config', '${this.googleTagId}');
        `;
          document.head.appendChild(gaScript);
        }
      });
  }
}
