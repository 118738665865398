import { ApparatusMark } from './../shared/mark/apparatus-mark.model';
import { Apparatus } from './../shared/apparatus/apparatus.model';
import { Event } from './../shared/event/event.model';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, interval } from 'rxjs';
import { TypeResult } from 'src/live/core/search/type-result.enum';
import { Category } from '../shared/category/category.model';
import { EntityType } from '../shared/entity-type.enum';
import { Entity } from '../shared/entity/entity.model';
import { EventService } from '../shared/event/event.service';
import { HomeService } from '../shared/home.service';
import { CorpsMark } from '../shared/mark/corps-mark.model';
import { ResultService } from '../shared/result/result.service';
import { finalize } from 'rxjs/operators';
import { UserService } from 'src/live/core/user/user.service';
import { DetailsDialogComponent } from './details-dialog/details-dialog.component';
import { DecimalPipe } from '@angular/common';
import { Team } from '../shared/team/team.model';
import { Group } from '../shared/group/group.model';
import { Context } from '../shared/context.model';
import { Criterion } from '../shared/criterion.enum';
import { Discipline } from '../shared/discipline/discipline.model';
import { DisciplineService } from '../shared/discipline/discipline.service';

@Component({
  selector: 'live-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.small.scss', './result.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ResultComponent implements OnInit, OnDestroy {
  // columns the tab has to display. There names has to be the same than the property 'cdkColumnDef' of the html tab
  public displayedColumns = [];
  // handle the fact that we have to display the details or not
  public showDetails: boolean;
  // enum for HTML
  public entityTypeEnum = EntityType;
  // categories selected by the user
  public categories: Category[];
  // event selected by the user
  public event: Event;
  public events: Event[];
  // discipline selected by the user
  public eventDisciplines: Discipline[];
  // handle the visibility of the link to the list of categories
  public isLinkToCategoriesDisplayed: boolean;
  // ids
  public categoriesIds: string;
  public searchClub: string;
  public searchGym: string;
  public searchType: TypeResult;
  public isLoading = false;
  public isMobile: boolean;
  private refreshSub: Subscription;

  public baseHref = '';

  // colums when we don't display the details. These are always the same
  private displayedFixedColumns = ['position', 'name', 'city', 'total'];
  // subscription to the url params
  private paramSub: Subscription;

  constructor(
    private route: ActivatedRoute,
    private resultService: ResultService,
    private eventService: EventService,
    private router: Router,
    private homeService: HomeService,
    private userService: UserService,
    private dialog: MatDialog,
    private decimalPipe: DecimalPipe,
    private disciplineService: DisciplineService
  ) {}

  public ngOnInit() {
    this.isMobile = this.userService.isDeviceMobile;
    // if we have a context, we need to display the link to the list of categories to select
    if (this.homeService.getContext() != null) {
      this.isLinkToCategoriesDisplayed = true;
    }
    // By default, we don't display the details, so we push the fixed columns
    this.displayedColumns.push(this.displayedFixedColumns);
    this.paramSub = this.route.params.subscribe(() => {
      this.showDetails = false;
      this.searchClub = '';
      this.searchGym = '';
      this.searchType = null;
      this.displayedColumns = [];
      this.categoriesIds = null;
      this.categories = [];
      this.displayedColumns.push(this.displayedFixedColumns);
      const type: TypeResult = TypeResult[this.route.snapshot.paramMap.get('type')];
      if (!type) {
        // get the result of the categories selected
        this.getResult();

        // get the event selected
        const eventId = this.route.snapshot.paramMap.get('eventId');
        this.eventService.getEvent(parseInt(eventId, 10)).subscribe((event: Event) => {
          this.event = event;
          this.disciplineService
            .getDisciplines(this.event)
            .subscribe((disciplines: Discipline[]) => {
              this.eventDisciplines = disciplines;
            });
        });
        this.getInterEvents();
      } else {
        switch (type) {
          case TypeResult.CLUB:
            const club = this.route.snapshot.paramMap.get('id');
            this.searchClub = club;
            this.searchType = TypeResult.CLUB;
            this.getClubResult(club);
            break;
          case TypeResult.GYM:
            const licence = this.route.snapshot.paramMap.get('id');
            this.searchGym = licence;
            this.searchType = TypeResult.GYM;
            this.getGymResult(licence);
            break;
        }
      }
    });

    this.refreshSub = interval(30 * 1000).subscribe((tick) => {
      this.refreshResult();
    });

    this.baseHref = window.origin + '/result';
  }

  public ngOnDestroy() {
    this.refreshSub.unsubscribe();
  }

  /**
   * get the corps marks of a passage of a apparatus of an athlete or group of a category
   * @param category category
   * @param entity athlete or group
   * @param apparatus apparatus
   * @param numPassage passage
   */
  public getApparatusCorpsMark(
    category: Category,
    entity: Entity,
    apparatus: Apparatus,
    numPassage: number
  ): CorpsMark[] {
    const result: CorpsMark[] = [];
    if (entity.mark && entity.mark.appMarks) {
      const appMarks = entity.mark.appMarks.find((appMark) => appMark.codeApp === apparatus.code);

      if (appMarks && appMarks.passageMarks[numPassage]) {
        for (const corps of category.corpsMarksLabels) {
          let pushed = false;
          for (const corpsMark of appMarks.passageMarks[numPassage].corpsMarks) {
            if (corpsMark) {
              if (corpsMark.corps === corps) {
                result.push(corpsMark);
                pushed = true;
              }
            } else {
              // if we don't any marks, we return an empty mark in order to display '-' instead of nothing
              result.push(new CorpsMark());
              pushed = true;
            }
          }
          if (!pushed) {
            result.push(new CorpsMark());
          }
        }
      } else {
        // if we don't any marks, we return an empty mark in order to display '-' instead of nothing
        for (let index = 0; index < category.corpsMarksLabels.length; index++) {
          result.push(new CorpsMark());
        }
      }
    }

    return result;
  }

  /**
   * get the mark of an apparatus of an athlete of a team
   * @param team team
   * @param apparatus apparatus
   */
  public getTeamEntityAppMark(team: Team, apparatus: Apparatus, passageIndex: number): any[] {
    const result = [];
    if (team.entities) {
      for (const entity of team.entities) {
        if (entity.mark && entity.mark.appMarks) {
          const apparatusMark = entity.mark.appMarks.find(
            (appMark) => appMark.codeApp === apparatus.code
          );
          if (apparatusMark) {
            if (apparatusMark.passageMarks) {
              if (apparatusMark.passageMarks[passageIndex]) {
                result.push(apparatusMark.passageMarks[passageIndex]);
              } else {
                // if we don't any marks, we return an empty mark in order to display '-' instead of nothing
                result.push(new ApparatusMark());
              }
            } else {
              if (passageIndex === 0) {
                result.push(apparatusMark);
              }
            }
          } else {
            // if we don't any marks, we return an empty mark in order to display '-' instead of nothing
            result.push(new ApparatusMark());
          }
        } else {
          result.push(new ApparatusMark());
        }
      }
    }

    return result;
  }

  /**
   * get the mark of an app
   * @param entity athlete or group or team
   * @param apparatus apparatus
   * @param numPassage passage
   */
  public getAppMark(
    entity: Team | Entity,
    apparatus: Apparatus,
    numPassage: number
  ): number | string {
    if (entity.mark && entity.mark.appMarks) {
      const apparatusMark = entity.mark.appMarks.find(
        (appMark) => appMark.codeApp === apparatus.code
      );
      if (apparatusMark) {
        if (apparatusMark.passageMarks) {
          if (apparatusMark.passageMarks[numPassage]) {
            return this.decimalPipe.transform(
              apparatusMark.passageMarks[numPassage].value,
              '1.3-3'
            );
          }
        } else {
          // if we have an appMark but no passage Mark, we return the mark only if the index of passage is zero
          if (apparatusMark.value !== 0 && numPassage === 0) {
            return this.decimalPipe.transform(apparatusMark.value, '1.3-3');
          } else {
            // if we display the results of a team, there is no passage team mark. So we prefer returning null
            if (entity.type === EntityType.Team && numPassage !== 0) {
              return null;
            }
          }
        }
      }
    }
    // if we don't any marks, we return '-' instead of nothing
    return '-';
  }

  /**
   * handle the click on the slider
   */
  public toggleDetails(): void {
    this.showDetails = !this.showDetails;
  }

  /**
   * handle the click on a tab
   * @param category category
   */
  public expandCategoryResult(category: Category) {
    category.areColumnsDisplayed = !category.areColumnsDisplayed;
  }

  public expandSocialShare(event: any, category: Category) {
    event.stopPropagation();
    category.areSocialDisplayed = !category.areSocialDisplayed;
  }

  /**
   * get the icone to display of apparatus
   * @param app apparatus
   */
  public getCSSClass(app: string, category: Category): string {
    if (category.codeDiscipline && category.codeDiscipline.length > 0) {
      return category.codeDiscipline + '-' + app.replace(/\s/g, '').toUpperCase();
    } else {
      return app.replace(/\s/g, '').toUpperCase();
    }
  }

  /**
   * handle the click to navigate to the list of categories
   */
  public goBackToCategories(): void {
    if (!this.isLinkToCategoriesDisplayed || this.isLinkToCategoriesDisplayed === undefined) {
      this.generateContext();
    }
    // we need this boolean to know we are coming from the result
    this.homeService.needContext = true;
    this.router.navigate(['/home']);
  }

  /**
   * refresh the result
   */
  public refreshResult(): void {
    if (!this.isLoading) {
      this.isLoading = true;
      // we save the tab that are open
      const codes = this.categories
        .filter((category: Category) => category.areColumnsDisplayed === true)
        .map((cat: Category) => cat.code);
      // get the result
      this.resultService
        .getResult(this.categoriesIds)
        .pipe(finalize(() => (this.isLoading = false)))
        .subscribe((categories: Category[]) => {
          this.categories = categories;
          // open the tab that were open
          this.categories.map((newCat: Category) => {
            if (codes.indexOf(newCat.code) !== -1) {
              newCat.areColumnsDisplayed = true;
            }
          });
        });
    }
  }

  /**
   * get the club's result when the user has been serching for the club via the search function
   * @param club club
   */
  private getClubResult(club: string): void {
    this.resultService.getClubCategories(club).subscribe((res) => {
      if (res && res.length > 0) {
        this.categoriesIds = res.join(',');
        this.getCategoriesResult(this.categoriesIds);
      }
    });
  }
  /**
   * Open the dialog to see the details of a mark of a team
   * @param row athlete, team or group, depending of the current category
   * @param category category
   * @param entity athlete we are clicking on. It can be the whole object or the index on the list of athlete
   */
  public openDialogForDetails(category: Category, row: Team, entity: Entity | number): void {
    // if the type of the category is different than Team and the details are not shown, we do nothing
    this.dialog.open(DetailsDialogComponent, {
      data: {
        category,
        row,
        entity,
      },
      width: '90%',
      height: 'auto',
    });
  }

  /**
   * Open the dialog to see the details of a group composition
   * @param row athlete, team or group, depending of the current category
   * @param category category
   */
  public openDialogForGroupDetails(category: Category, row: Group): void {
    // if the type of the category is different than Team and the details are not shown, we do nothing
    this.dialog.open(DetailsDialogComponent, {
      data: {
        category,
        row,
      },
      width: '90%',
      height: 'auto',
    });
  }

  /**
   * allow us to know if a line in the tab has to be unlighted
   * @param row team or group or athlete
   */
  public isSelected(row: any): boolean {
    let result = false;
    if (this.searchType) {
      switch (this.searchType) {
        case TypeResult.CLUB:
          result = row.idClub === this.searchClub;
          break;
        case TypeResult.GYM:
          switch (row.type) {
            case EntityType.Athlete:
              const ath: Entity = row;
              if (ath) {
                result = ath.licence === this.searchGym;
              }
              break;

            case EntityType.Group:
              const ent: Entity = row; // FIXME : Entity ou Group ?
              if (ent && ent.entities) {
                const idx = ent.entities.findIndex((e) => e.licence === this.searchGym);
                result = idx !== -1;
              }
              break;

            case EntityType.Team:
              const equ: Team = row;
              if (equ && equ.entities) {
                const index = equ.entities.findIndex((e) => e.licence === this.searchGym);
                result = index !== -1;
              }
              break;

            default:
              break;
          }
          break;
      }
    } else {
      result = false;
    }
    return result;
  }

  /**
   * get the athlete's result when the user has been serching for the athlete via the search function
   * @param licence licence num of the athlete
   */
  private getGymResult(licence: string): void {
    this.resultService.getGymCategories(licence).subscribe((res) => {
      if (res && res.length > 0) {
        this.showDetails = true;
        this.categoriesIds = res.join(',');
        this.getCategoriesResult(this.categoriesIds);
      }
    });
  }

  /**
   * get the result of the categories selected
   */
  private getResult(): void {
    this.categoriesIds = this.route.snapshot.paramMap.get('ids');
    this.getCategoriesResult(this.categoriesIds);
  }

  /**
   * get the result of the categories selected (in the url)
   * @param categoriesIds ids of the categories selected
   */
  private getCategoriesResult(categoriesIds: string): void {
    this.resultService.getResult(categoriesIds).subscribe((categories: Category[]) => {
      // we need to fill the array of columns with the name of the apparatus we will have to display (for details mode)
      this.buildTableColumns(categories);
      this.categories = categories;
      // open the first tab
      this.categories[0].areColumnsDisplayed = true;
    });
  }

  /**
   * fill the array of columns with the name of the apparatus we will have to display (for details mode)
   * @param categories categories
   */
  private buildTableColumns(categories: Category[]): void {
    categories.forEach((category: Category) => {
      const columns = ['position', 'name', 'city', 'note'];
      for (const app of category.apparatus) {
        for (let i = 0; i < app.nbPassage; i++) {
          columns.push(app.label + i);
        }
      }
      // push the total column at the end
      columns.push('total');
      this.displayedColumns.push(columns);
    });
  }

  public getWidth(cat: Category): string {
    const def = 256;
    let nbPix = 0;
    if (this.showDetails) {
      nbPix = (this.displayedColumns[1].length - 5) * 50;
    }
    return def + nbPix + 'px';
  }

  private generateContext() {
    const context = new Context();
    context.lastCriterions = [];
    context.eventSelected = this.event;
    context.events = this.events;
    context.interEvents = this.events;
    context.lastCriterions.push(Criterion.Event);

    const discipline = this.eventDisciplines.find(
      (d) => d.code === this.categories[0].codeDiscipline
    );
    context.disciplineSelected = discipline;
    context.disciplines = this.eventDisciplines;
    context.lastCriterions.push(Criterion.Discipline);

    context.categories = this.event.categories;
    context.categoriesSelected = this.categories;
    this.homeService.saveContext(context);
  }

  private getInterEvents(): void {
    this.eventService.getInterEvents().subscribe((eventsReturned: Event[]) => {
      if (eventsReturned && eventsReturned.length > 0) {
        this.events = eventsReturned;
      }
    });
  }
}
